
@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Plus+Jakarta+Sans:wght@400;800&family=Poppins&family=Roboto&display=swap');


body {
    font-family: 'Montserrat', sans-serif;
}

img.profile {
height: 192px;
width: 192px;
margin-top: 20px;
border-radius: 50%;
/* margin: 10px 0px 10px 0px; */
}

h1 {
    color: rgb(17, 201, 241);
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 800;
    margin: 10px 0px 20px 0px;
}

p {
    margin: 10px 0px 10px 0px;
    font-family: 'Poppins', sans-serif;
}

.viewport-height {
    height: 70vh;
}
  .mouse-scroll {
    display: inline-block;
    line-height: 18px;
    font-size: 13px;
    font-weight: normal;
    color: #7f8c8d;
    color: #fff;
    font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
    letter-spacing: 2px;
    margin-top: 100px;
    text-decoration: none;
    overflow: hidden;
  }
  .mouse-scroll .mouse {
    position: relative;
    display: block;
    width: 2.5px;
    height: 80px;
    background-color: rgb(17, 201, 241);
    margin: 0 auto 20px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border: 1px solid rgb(17, 201, 241);
    border-radius: 23px;
  }

p a {
    margin: 10px 15px 10px 0px;
    font-size: 15px;
    color: rgb(17, 201, 241);
}