.buttons a {
    text-decoration: none;
    color: rgb(109, 117, 125);
    border: 2px solid #0f1013;
    background-color: #0f1013;
    padding: 3px 10px 3px 10px;
    border-radius: 5px;
    font-size: 20px;
}

.buttons a:hover {
    border-color: rgb(109, 117, 125);
}