.footer {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 8px;
        text-align: center;
}

.icons a:hover {
color: rgb(109, 117, 125)
}