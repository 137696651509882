@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Poppins&family=Roboto&display=swap');
.nav {
    /* border: 2px solid rgb(17, 201, 241); */
    border-radius: 10px;
    margin-top: 30px;
}
a, a:visited, a:hover, a:active {
    color: inherit;
  }

.nav-item a {
    color: rgb(17, 201, 241);
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    padding-bottom: 2px;
    margin: 20px;
    text-decoration: none;
}

.nav-item a:hover {

    color: rgb(109, 117, 125);
}