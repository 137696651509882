body {
  max-width: 800px; 
	margin: 0 auto !important; 
	float: none !important;
  /* padding: 20px; */
  background-color: #111215;
  color: rgb(17, 201, 241); 
  padding: 15px;
}

html,body {
  background-color: #111215;
}