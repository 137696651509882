@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Plus+Jakarta+Sans:wght@400;800&family=Poppins&family=Roboto&display=swap');

.pj-viewport-height {
     height: 80vh; 
}


.card {
    margin: 3px;
    padding: 0;
    background-color: #0f1013;
    text-align: center;
}

.card-title {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 800;
}

.card-text {
    text-align: left;
    font-size: 13px;
}

h3 {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 800;
    color: rgb(109, 117, 125);
}
.card img {
    border-radius: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin: none;
    width: 100%;
    height: 140px;
}

.text-muted p {
    display: inline-block;
    margin-right: 5px;
    font-family: 'Poppins', sans-serif;
    background-color: rgb(109, 117, 125);
    color: #0f1013;
    padding: 3px;
    font-weight: 500;
    border-radius: 3px;
}

.card-text a {
    background-color: rgb(17, 201, 241);
    color: black;
    text-decoration: none;
    margin-top: 50px;
    border-radius: 3px;
    padding: 3px;
}